import { EventManager } from '@/components/EventManager'
import pixabayButton from '@/plugins/pixabayButton'

export default {
    plugins: ['image', 'link', 'media', 'lists', 'wordcount', 'code', 'quickbars', 'pixabay', 'paste'],
    toolbar: `undo redo | bold italic underline | formatselect | numlist bullist | link blockquote code | pixabay`,
    selector: 'textarea',
    quickbars_insert_toolbar: false,
    quickbars_selection_toolbar: 'bold italic | quicklink h1 h2 h3 blockquote',
    a_plugin_option: true,
    a_configuration_option: 400,
    autosave_interval: '1s',
    menubar: false,
    height: 600,
    tox_toolbar: 'pixabay',
    setup(editor) {
        window.tinymce.PluginManager.add('pixabay', pixabayButton)
        editor.on('WordCountUpdate', e => {
            EventManager.$emit('wordCountChange', e)
        })
    },
    cleanup_on_startup: true,
    fix_list_elements: false,
    fix_nesting: false,
    fix_table_elements: false,
    paste_use_dialog: true,
    paste_auto_cleanup_on_paste: true,
    paste_webkit_styles: 'none',
    paste_retain_style_properties: 'none',
    paste_remove_styles: true,
    valid_elements: [
        'b',
        'i',
        'em',
        'strong',
        'a[href|target]',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'p',
        'ul',
        'ol',
        'li',
        'u',
        'strike',
        'img[src|alt=|title]',
        'blockquote',
        'pre'
    ]
}
