<template>
    <div>
        <div v-if="preloading">
            <button class="btn btn-secondary"><i class="fas fa-cog fa-spin"/> {{ $t('global.loading') }}...</button>
        </div>
        <div v-else>
            <div v-if="role === 'admin'" class="mb-3">
                <router-link to="/admin/redactor">
                    <i class="fas fa-arrow-circle-left"/> Revenir à l'admin
                </router-link>
            </div>
        </div>

        <div v-if="!preloading && error">
            <WritingPluginErrorNotification>
                La commande a été placée automatiquement en erreur.<br/>
                <div v-if="errorPlugins.response && errorPlugins.response.status">
                    {{ errorPlugins.response.data.message }}
                </div>
            </WritingPluginErrorNotification>
        </div>

        <div v-if="writing && !preloading && !error">
            <WritingDoneNotification v-if="isWritingDone()"/>

            <template v-if="isWritingInProgress()">
                <WritingReviewedNotification v-if="writing.reason" :reason="writing.reason"/>
                <WritingIsPrioNotification v-if="writing.isPrio" :timeout="writing.timeout"/>
            </template>

            <template v-if="isWritingInReview()">
                <div class="row mb-2">
                    <div class="col d-block">
                        <ReviewWritingNotification :writing="writing"/>
                    </div>
                    <div
                        v-if="
                            role === 'admin' &&
                            writing.duplicate_content &&
                            writing.duplicate_content.duplicate_percentage
                        "
                        class="col-md-auto"
                    >
                        <Balance
                            :amount="writing.duplicate_content.duplicate_percentage + '%'"
                            icon="fas fa-copy"
                            subtitle="Taux de duplication"
                            @click="openDuplicateModal"
                            title=""
                        />
                    </div>
                </div>

                <WritingReviewedNotification
                    v-if="writing.reason && writing.review_counter > 0"
                    :reason="writing.reason"
                >
                    <div class="mt-2">Nombre de relecture(s) : {{ writing.review_counter }}</div>
                </WritingReviewedNotification>
            </template>

            <RedactorPixabayModal ref="pixabayModal" @image:selected="addImage"/>
            <RedactorPixabayModal ref="mainImage" @image:selected="addMainImage"/>

            <div class="row box">
                <div class="col-md-12 col-xl-8 mb-3">
                    <div v-if="(isSpot || isAIPreorder) && estimatedImages.length > 0">
                        <ul class="gallery">
                            <li v-for="image in estimatedImages" :key="image.id" class="gallery-card">
                                <img :src="image.webformatURL" :alt="image.keyword" :title="image.keyword"/>
                                <div class="radio-block">
                                    <b-field class="mb-0">
                                        <b-radio
                                            class="mb-0"
                                            name="content"
                                            native-value="content"
                                            @input="setContentImage(image)"
                                        >
                                            Contenu
                                        </b-radio>
                                    </b-field>
                                    <b-field class="mb-0">
                                        <b-radio
                                            class="mb-0"
                                            name="spotlight"
                                            native-value="spotlight"
                                            @input="setSpotlightImage(image)"
                                        >
                                            A la une
                                        </b-radio>
                                    </b-field>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <form>
                        <div class="form-group">
                            <label for="orderUrl">
                                {{ $t('redactor.customer-page') }}
                            </label>
                            <input
                                v-if="isPreorder || isSpot"
                                id="orderUrl"
                                :value="writing && writing.site ? writing.site.url : writing.website.url"
                                class="form-control"
                                readonly
                                type="text"
                            />
                            <input
                                v-else
                                id="orderUrl"
                                v-model="writing.page"
                                class="form-control"
                                readonly
                                type="text"
                            />
                        </div>

                        <div v-if="writing.has_wordpress_plugin && wordpressContent" class="row">
                            <div class="col-md-12 col-lg-6">
                                <div class="form-group">
                                    <label for="wordpressTitle">
                                        {{ $t('redactor.website-title') }}
                                    </label>
                                    <input
                                        id="wordpressTitle"
                                        v-model="wordpressContent.title"
                                        class="form-control"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6">
                                <div class="form-group">
                                    <label for="wordpressMeta">
                                        {{ $t('redactor.website-meta') }}
                                    </label>
                                    <input
                                        id="wordpressMeta"
                                        v-model="wordpressContent.meta.title"
                                        class="form-control"
                                        type="text"
                                    />
                                </div>
                            </div>

                            <div v-if="errorPlugins" class="col-12 mb-4">
                                <small class="text-danger">{{ errorPlugins }}</small>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label for="actualContent">
                                        {{ $t('redactor.actual-content') }}
                                    </label>
                                    <textarea
                                        id="actualContent"
                                        v-model="wordpressContent.content"
                                        class="form-control"
                                        cols="30"
                                        name="actualContent"
                                        readonly
                                        rows="10"
                                    />
                                </div>
                            </div>

                            <div v-if="!this.writing.site.partenaire_id" class="col-12">
                                <div class="form-group">
                                    <label for="publishedDate">
                                        {{ $t('redactor.publishdate') }}
                                    </label>
                                    <input
                                        id="publishedDate"
                                        v-model="wordpressContent.date"
                                        class="form-control"
                                        readonly
                                        type="text"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 d-flex justify-content-between">
                                <div/>
                                <div class="d-flex flex-column flex-md-row align-items-center">
                                    <span :class="wordCounter >= wordMini ? 'is-success' : 'is-danger'" class="tag">
                                        {{ wordCounter }} / {{ writing.words_count }}
                                    </span>

                                    <span
                                        v-if="isPreorder || isSpot"
                                        :class="isImagePresent ? 'is-success' : 'is-danger'"
                                        class="tag ml-2"
                                    >
                                        Image dans le contenu
                                    </span>

                                    <template v-if="isPreorder || isSpot">
                                        <span
                                            :class="[
                                                writing.image && writing.image.length > 0 ? 'is-success' : 'is-danger'
                                            ]"
                                            class="ml-2 tag"
                                        >
                                            <span>Image à la une</span>
                                        </span>
                                        <span
                                            v-if="isPreorder"
                                            :class="[isKeywordInH2Present ? 'is-success' : 'is-danger']"
                                            class="ml-2 tag"
                                        >
                                            <span>Mot-clé dans le h2</span>
                                        </span>
                                        <span
                                            v-else
                                            :class="[isH2Present ? 'is-success' : 'is-danger']"
                                            class="ml-2 tag"
                                        >
                                            <span>Contient un h2</span>
                                        </span>
                                    </template>
                                    <template v-if="isSpot || isOrder">
                                        <span v-if="!linkAnchorPresent" class="ml-2 tag">
                                            <i class="fas fa-times"/>
                                            <span class="ml-1">
                                                {{ $t('redactor.link-anchor-present') }}
                                            </span>
                                        </span>

                                        <span v-if="linkAnchorPresent" class="ml-2 tag is-success">
                                            <svg
                                                class="bi bi-check-circle-fill"
                                                fill="currentColor"
                                                height="12"
                                                viewBox="0 0 16 16"
                                                width="12"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                                                />
                                            </svg>
                                            <span class="ml-1">
                                                {{ $t('redactor.link-anchor-present') }}
                                            </span>
                                        </span>
                                    </template>

                                    <div class="ml-md-4">
                                        <b-tooltip label="Contenu sauvegardé automatiquement toutes les deux minutes">
                                            <b-button
                                                :type="!autoSaved ? 'is-warning is-light' : 'is-success'"
                                                @click.prevent="doSaveContent(true)"
                                            >
                                                <i v-if="saveInProgress" class="fas fa-circle-notch fa-spin mr-1"/>
                                                <i v-else-if="autoSaved" class="fas fa-check mr-1"/>
                                                <i v-else class="fas fa-save mr-1"/>
                                                Sauvegarder le contenu
                                            </b-button>
                                        </b-tooltip>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="newContentContent">
                                        {{ $t('redactor.content') }}
                                    </label>
                                    <editor
                                        v-if="activateEditor"
                                        ref="editor"
                                        v-model="content"
                                        model-events="change keydown blur paste undo redo"
                                        api-key="zog5im5wasvx7hbf6rafpbwj0kcg0t1hpmcg6em8jyieb87n"
                                        :disabled="editorDisabled"
                                        :init="tinymceConfig"
                                        @onChange="handleContentChanges"
                                        @input="autoSaved = false"
                                    />
                                    <small v-if="errorContent" class="text-danger">
                                        {{ errorContent }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-12 mb-4 d-flex justify-content-between">
                                <div/>
                                <div class="d-flex flex-column flex-md-row align-items-center">
                                    <span :class="wordCounter >= wordMini ? 'is-success' : 'is-danger'" class="tag">
                                        {{ wordCounter }} / {{ writing.words_count }}
                                    </span>

                                    <span
                                        v-if="isPreorder || isSpot"
                                        :class="isImagePresent ? 'is-success' : 'is-danger'"
                                        class="tag ml-2"
                                    >
                                        Image dans le contenu
                                    </span>

                                    <template v-if="isPreorder || isSpot">
                                        <span
                                            :class="[
                                                writing.image && writing.image.length > 0 ? 'is-success' : 'is-danger'
                                            ]"
                                            class="ml-2 tag"
                                        >
                                            <span>Image à la une</span>
                                        </span>
                                        <span
                                            v-if="isPreorder"
                                            :class="[isKeywordInH2Present ? 'is-success' : 'is-danger']"
                                            class="ml-2 tag"
                                        >
                                            <span>Mot-clé dans le h2</span>
                                        </span>
                                        <span
                                            v-else
                                            :class="[isH2Present ? 'is-success' : 'is-danger']"
                                            class="ml-2 tag"
                                        >
                                            <span>Contient un h2</span>
                                        </span>
                                    </template>
                                    <template v-if="isSpot || isOrder">
                                        <span v-if="!linkAnchorPresent" class="ml-2 tag">
                                            <i class="fas fa-times"/>
                                            <span class="ml-1">
                                                {{ $t('redactor.link-anchor-present') }}
                                            </span>
                                        </span>

                                        <span v-if="linkAnchorPresent" class="ml-2 tag is-success">
                                            <svg
                                                class="bi bi-check-circle-fill"
                                                fill="currentColor"
                                                height="12"
                                                viewBox="0 0 16 16"
                                                width="12"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                                                />
                                            </svg>
                                            <span class="ml-1">
                                                {{ $t('redactor.link-anchor-present') }}
                                            </span>
                                        </span>
                                    </template>

                                    <div class="ml-md-4">
                                        <b-tooltip label="Contenu sauvegardé automatiquement toutes les deux minutes">
                                            <b-button
                                                :type="!autoSaved ? 'is-warning is-light' : 'is-success'"
                                                @click.prevent="doSaveContent(true)"
                                            >
                                                <i v-if="saveInProgress" class="fas fa-circle-notch fa-spin mr-1"/>
                                                <i v-else-if="autoSaved" class="fas fa-check mr-1"/>
                                                <i v-else class="fas fa-save mr-1"/>
                                                Sauvegarder le contenu
                                            </b-button>
                                        </b-tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="this.isWritingInProgress()" class="buttons">
                            <b-button
                                :class="['button', !this.isWritingInProgress() ? 'is-success' : 'is-primary']"
                                :disabled="!canPublish"
                                :loading="storeLoading"
                                @click.prevent="postContent"
                            >
                                Envoyer le contenu
                            </b-button>
                        </div>

                        <div v-if="writing.writing_status_id === 3">
                            <div class="buttons pt-4 mb-0">
                                <b-button
                                    :disabled="!canPublish"
                                    :loading="storeLoading"
                                    class="btn button is-success is-large"
                                    @click.prevent="markWritingAsReviewed"
                                >
                                    Valider la commande
                                </b-button>
                            </div>
                            <div>
                                <b-field
                                    :message="
                                        writing.has_wordpress_plugin
                                            ? 'Le contenu sera publié via le plugin'
                                            : 'Le contenu sera envoyé par mail'
                                    "
                                    class="ml-1"
                                    type="is-info"
                                />
                            </div>
                        </div>
                    </form>
                </div>

                <div class="col-md-12 col-xl-4 p-0 pl-2">
                    <div class="row mt-2">
                        <CustomerBrief
                            :can-publish="canPublish"
                            :store-loading="storeLoading"
                            @decline="doStoreRefusal"
                            @review:fail="doPublicationDeclined"
                            @publish="postContent"
                        />

                        <div v-if="isPreorder || isSpot" class="col-md-12">
                            <div class="card border-0 mb-3 overflow-x-hidden">
                                <div class="card-header border-0 shadow-sm px-3 justify-content-between">
                                    Image à la une
                                </div>
                                <div class="p-3">
                                    <button
                                        v-if="!writing.image"
                                        class="btn btn-secondary"
                                        @click.prevent="$refs.mainImage.openModal()"
                                    >
                                        Ajouter une image
                                    </button>

                                    <div v-if="writing.image">
                                        <img :src="writing.image" alt=""/>

                                        <a href="#" @click.prevent="writing.image = null">Supprimer</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import WritingDoneNotification from '@/components/Redactor/Notifications/WritingDoneNotification'
import WritingIsPrioNotification from '@/components/Redactor/Notifications/WritingIsPrioNotification'
import ReviewWritingNotification from '@/components/Redactor/Notifications/ReviewWritingNotification'
import Balance from '../../components/UI/Balance'
import {EventManager} from '@/components/EventManager'

import Editor from '@tinymce/tinymce-vue'
import tinymceConfig from '@/config/tinymce'
import WritingPluginErrorNotification from '@/components/Redactor/Notifications/WritingPluginErrorNotification.vue'
import WritingReviewedNotification from '@/components/Redactor/Notifications/WritingReviewedNotification.vue'
import RedactorPixabayModal from '@/components/Redactor/Modals/RedactorPixabayModal.vue'
import CustomerBrief from '@/components/Redactor/Utils/CustomerBrief.vue'
import Ur from "vuejs-datepicker/dist/locale/translations/ur";

const punycode = require('punycode/');

export default {
    name: 'Writing',
    title: 'writing.title',
    components: {
        CustomerBrief,
        RedactorPixabayModal,
        WritingReviewedNotification,
        WritingPluginErrorNotification,
        Balance,
        ReviewWritingNotification,
        WritingIsPrioNotification,
        WritingDoneNotification,
        Editor
    },

    data: function () {
        return {
            step: 1,
            saveInProgress: false,
            autoSaved: false,

            redactor: null,

            refusalText: '',
            refusalReason: null,

            newAnchor: '',
            newAnchorTwo: '',
            anchorUpdated: false,
            errorAnchor: '',
            errorAnchorTwo: '',

            has_wordpress_plugin: false,
            wordpressContent: null,

            wordCounter: 0,
            isKeywordInH2Present: false,
            isH2Present: false,
            linkAnchorPresent: false,
            isImagePresent: false,
            wordMini: 0,

            newContent: '',
            errorContent: '',
            errorPlugins: '',
            error: false,

            stayPage: true,
            activateEditor: false,

            editorDisabled: false,

            //UI
            preloading: true,
            storeLoading: false,
            timer: null,
            role: this.$store.state.auth.user ? this.$store.state.auth.user.role : null,

            estimatedImages: []
        }
    },
    created() {
        this.getData()
        EventManager.$on('pixabay:show', () => {
            if (this.$refs.pixabayModal) {
                this.$refs.pixabayModal.openModal('processing')
            }
        })
        EventManager.$on('wordCountChange', ({wordCount: {words}}) => {
            this.wordCounter = words
        })
    },
    computed: {
        ...mapState('redactors', {
            writing: state => state.writing,
            decline: state => state.decline
        }),
        tinymceConfig() {
            return tinymceConfig
        },
        content: {
            get() {
                return this.$store.state.redactors.writing.content
            },
            set(value) {
                this.$store.commit('redactors/setWritingContent', value)
            }
        },
        isPreorder() {
            return this.writing.entity_type === 2
        },
        isAIPreorder() {
            return this.writing.entity_type === 2 && this.writing.related_order.type === 'textbulker'
        },
        isSpot() {
            return this.writing.entity_type === 6
        },
        isOrder() {
            return this.writing.entity_type === 1
        },

        canPublish() {
            let rulesToValidate = [
                this.wordCounter >= this.wordMini,
                ...(this.isSpot || this.isPreorder
                    ? [
                        this.writing.category !== null,
                        this.writing.author !== null,
                        this.isImagePresent,
                        this.writing.image && this.writing.image.length > 0
                    ]
                    : []),
                ...(this.isOrder || this.isSpot ? [this.linkAnchorPresent] : []),
                ...(this.isSpot ? [this.isH2Present] : []),
                ...(this.isPreorder ? [this.isKeywordInH2Present] : [])
            ]

            return rulesToValidate.every(rule => rule === true)
        }
    },
    methods: {
        ...mapActions('redactors', {
            getWriting: 'getWriting',
            saveContent: 'saveContent',
            declineWriting: 'declineWriting',
            askForWriting: 'askForWriting',
            timeoutWriting: 'timeoutWriting',
            updateWriting: 'updateWriting',
            saveWriting: 'saveWriting',
            declinePublication: 'declinePublication',
            setWritingReviewed: 'setWritingReviewed',
            searchImages: 'searchImages'
        }),

        openDuplicateModal() {
            let links = Object.keys(this.writing.duplicate_content.duplicate_links)
            let message = links.length > 0 ? links.join('<br/>') : 'Aucun'

            this.$buefy.dialog.alert({
                title: 'Liens dupliqués',
                message: message,
                confirmText: 'Ok',
                trapFocus: true
            })
        },

        addImage(image) {
            this.$refs.pixabayModal.closeAndResetModal()
            this.content += `<p><img src="${image.largeImageURL}" title="${this.writing.related_order.meta_title}" alt="${this.writing.related_order.keyword}" /></p>`
        },

        addMainImage(image) {
            this.$refs.mainImage.closeAndResetModal()
            this.writing.image = image.largeImageURL

            this.handleContentChanges()
        },

        handleContentChanges() {
            this.checkTimeout()

            let parser = new DOMParser()
            let html = parser.parseFromString(this.content, 'text/html')

            if (this.isSpot) {
                this.checkIsH2Present(html)
                this.checkLinkAndAnchorArePresent(html)
            } else if (this.isOrder) {
                this.checkLinkAndAnchorArePresent(html)
            } else if (this.isPreorder) {
                this.checkIsKeywordInH2Present(html)
            }

            this.checkImagePresent(html)

            this.doSaveContent(true)
        },

        getData() {
            this.getWriting(this.$route.query.id)
                .then(async () => {
                    this.activateEditor = true

                    if (this.isWritingInProgress()) {
                        this.initEvents()
                        this.setMinimalWords()
                    }

                    if (this.isSpot && this.writing.related_order.generated_keywords) {
                        const generatedKeywords = this.writing.related_order.generated_keywords.split('|')
                        await this.getPixabayImages([generatedKeywords[0], generatedKeywords[1]])
                    }

                    if (this.isAIPreorder && this.writing.related_order.keywords_from_title) {
                        const generatedKeywords = this.writing.related_order.keywords_from_title.split('|')
                        await this.getPixabayImages([generatedKeywords[0], generatedKeywords[1]])
                    }

                    this.handleContentChanges()
                })
                .catch(error => {
                    this.error = true
                    this.$store.dispatch('toastError', error)
                    this.errorPlugins = error
                })
                .finally(() => {
                    this.preloading = false
                })
        },

        async getPixabayImages(generatedKeywords) {
            for (const generatedKeywordElement of generatedKeywords) {
                await this.searchImages(generatedKeywordElement)
                    .then(response => {
                        response.images.hits.forEach(image => {
                            image.keyword = generatedKeywordElement
                        })
                        this.estimatedImages.push(...response.images.hits)
                    })
                    .catch(error => {
                        this.$store.dispatch('toastError', error)
                    })
            }
            this.autoChooseImage()
        },

        setContentImage(image) {
            const contentImage = `<p class='image'><img src='${image.largeImageURL}' data-id='${image.id}' title='${this.writing.related_order.meta_title}' alt='${this.writing.related_order.keyword}' width='200px' height='auto' /></p>`

            let parser = new DOMParser()
            let html = parser.parseFromString(this.content, 'text/html')

            const imageInserted = html.querySelector('.image')
            if (imageInserted) {
                imageInserted.remove()
            }

            const newElementImage = parser.parseFromString(contentImage, 'text/html')

            const getAllh2 = html.querySelectorAll('h2')
            if (getAllh2.length > 1) {
                const randomIndex = Math.floor(Math.random() * (getAllh2.length - 1)) + 1
                const parentNode = getAllh2[randomIndex].parentNode
                parentNode.insertBefore(newElementImage.body.firstChild, getAllh2[randomIndex])
            } else {
                html.body.appendChild(newElementImage.body.firstChild)
            }

            this.content = html.body.innerHTML
            this.handleContentChanges()
        },

        setSpotlightImage(image) {
            this.writing.image = image.largeImageURL

            this.handleContentChanges()
        },

        autoChooseImage() {
            if (this.estimatedImages.length > 0) {
                let parser = new DOMParser()
                let html = parser.parseFromString(this.content, 'text/html')

                const imageInserted = html.querySelector('.image')
                if (!imageInserted) {
                    const contentImage =
                        this.estimatedImages[Math.floor(Math.random() * this.estimatedImages.length)]
                    this.setContentImage(contentImage)
                }

                if (this.writing.image == null) {
                    let spotlightImage =
                        this.estimatedImages[Math.floor(Math.random() * this.estimatedImages.length)]
                    this.setSpotlightImage(spotlightImage)
                }
            }
        },

        isWritingInProgress() {
            return this.writing.writing_status_id === 2
        },

        isWritingInReview() {
            return this.writing.writing_status_id === 3
        },

        isWritingDone() {
            return this.writing.writing_status_id === 6
        },

        checkTimeout() {
            // If the writing is not "in progress", it means that it needs to be done or it's done
            // So we don't need to check for timeout
            if (!this.isWritingInProgress()) return

            if (this.writing.isPrio && this.writing.timeout) {
                let now = this.moment()
                let timeout = this.moment(this.writing.timeout)

                if (now.diff(timeout, 'minutes') > 0) {
                    this.editorDisabled = true
                    this.timeoutWriting(this.writing.id)

                    this.$buefy.dialog.confirm({
                        title: 'TIME OUT !',
                        message: `<b>Vous avez dépasser la limite de temps allouée à cette commande prioritaire.</b><br/><br/>
                                Vous n'avez plus la possibilité de continuer votre rédaction sur cette commande.`,
                        confirmText: 'Nouvelle commande',
                        cancelText: 'Retour',
                        type: 'is-danger',
                        onConfirm: () => this.assignOrder(),
                        onCancel: () => (window.location.href = `/redactor`)
                    })
                }
            }
        },

        checkIsKeywordInH2Present(content) {
            let keyword = this.writing.related_order.keyword.toLowerCase()

            console.log(keyword)

            console.log(
                !![...content.querySelectorAll('h2')].find(h2 =>
                    h2.textContent.trim().toLowerCase().includes(keyword)
                )
            )

            this.isKeywordInH2Present = !![...content.querySelectorAll('h2')].find(h2 =>
                h2.textContent.trim().toLowerCase().includes(keyword)
            )
        },

        checkIsH2Present(content) {
            this.isH2Present = !!content.querySelector(`h2`) && content.querySelector('h2').innerText.trim() !== ''
        },

        initEvents() {
            this.$echo.private(`writings.${this.writing.id}`).listen('.WritingUnassigned', () => {
                this.$buefy.dialog.confirm({
                    title: 'Commande retirée',
                    message: `Cette commande vient de vous être retirée par un administrateur. <br/><br/>
                                Vous n'avez plus la possibilité de continuer votre rédaction sur cette commande.`,
                    confirmText: 'Nouvelle commande',
                    cancelText: 'Retour',
                    type: 'is-info',
                    onConfirm: () => this.assignOrder(),
                    onCancel: () => (window.location.href = `/redactor`)
                })

                this.$router.push('/redactor')
            })
        },

        setMinimalWords() {
            if (this.writing.words_count > 200) {
                this.wordMini = this.writing.words_count - this.writing.words_count * 0.02
            } else {
                this.wordMini = this.writing.words_count - this.writing.words_count * 0.05
            }
        },

        getPunycodeEncodedLink(link) {
            if (!link) return

            try {
                const url = new URL(link);
                url.hostname = punycode.toASCII(url.hostname)

                return url.toString()
            } catch (err) {
                return link
            }
        },

        checkLinkAndAnchorArePresent(content) {
            let primaryLink = this.getPunycodeEncodedLink(this.writing.primary_link)
            let secondaryLink = this.getPunycodeEncodedLink(this.writing.secondary_link)

            let anchors = content.querySelectorAll(`a[href='${primaryLink}']`)
            let anchorsTwo = content.querySelectorAll(`a[href='${secondaryLink}']`)

            anchors = Array.from(anchors).filter(anchor => {
                return anchor.innerText === this.writing.primary_anchor
            })
            anchorsTwo = Array.from(anchorsTwo).filter(anchor => {
                return anchor.innerText === this.writing.secondary_anchor
            })

            this.linkAnchorPresent = !!(anchors.length && (anchorsTwo.length || !this.writing.secondary_link))
        },

        checkImagePresent(content) {
            let images = content.querySelectorAll(`img`)

            this.isImagePresent = images.length > 0
        },

        postContent() {
            this.storeLoading = true
            this.saveWriting(false)
                .then(() => {
                    this.$buefy.dialog.confirm({
                        title: 'Rédiger une nouvelle commande ?',
                        message: `Votre commande a bien été enregistrée et transmise à NextLevel qui se chargera de publier la commande.`,
                        confirmText: 'Nouvelle commande',
                        cancelText: 'Retour',
                        type: 'is-success',
                        onConfirm: () => this.assignOrder(),
                        onCancel: () => (window.location.href = `/redactor`)
                    })
                })
                .catch(e => {
                    this.$store.dispatch('toast', {
                        type: 'error',
                        title: 'Une erreur est survenue',
                        message: e.response.data.message
                    })
                })
                .finally(() => {
                    this.storeLoading = false
                })
        },

        doStoreRefusal() {
            let content = {
                id: this.writing.id,
                ...this.decline
            }

            this.declineWriting(content)
                .then(() => {
                    //window.location.reload();
                })
                .catch(error => {
                    this.handleError(error, 'Impossible de stocker votre refus')
                })
                .finally(() => {
                    this.$buefy.dialog.confirm({
                        title: 'Traiter une nouvelle commande ?',
                        message: `Votre refus a bien été pris en compte. Vous pouvez au choix passer à la commande suivante ou revenir à votre tableau de bord`,
                        confirmText: 'Nouvelle commande',
                        cancelText: 'Retour',
                        type: 'is-info',
                        onConfirm: () => this.assignOrder(),
                        onCancel: () => (window.location.href = `/redactor`)
                    })
                })
        },

        assignOrder() {
            this.isAffectingWriting = true
            this.editorDisabled = true

            this.askForWriting(this.$store.state.auth.user.id)
                .then(data => {
                    this.$router.push(`/redactor/writing?id=${data.writing}`)

                    //pushing the same URL (with different arg) does not reload the page
                    location.reload()
                })
                .catch(e => {
                    this.$store.dispatch('toast', {
                        type: 'error',
                        title: 'Une erreur est survenue',
                        message: e.response.data.message
                    })

                    this.$router.push(`/redactor`)
                })
                .finally(() => {
                    this.isAffectingWriting = false
                })
        },

        doSaveContent(tempSave = true) {
            if (this.content != null && (this.content.length > 50 || !this.saveInProgress || tempSave)) {
                this.saveInProgress = true
                this.saveWriting(tempSave)
                    .then(() => {
                        this.autoSaved = true
                    })
                    .catch(error => {
                        this.handleError(error, "Une erreur s'est produire lors de l'enregistrement automatique.")
                    })
                    .finally(() => {
                        this.saveInProgress = false
                    })
            }
        },

        doPublicationDeclined() {
            this.loading = true

            this.declinePublication()
                .then(() => {
                    this.$store.dispatch('toast', {
                        type: 'success',
                        title: 'La commande a bien été sauvegardée',
                        message: 'La commande a été retournée au rédacteur pour retraitement'
                    })
                    this.$router.push('/admin/redactor')
                })
                .catch(e => {
                    this.$store.dispatch('toast', {
                        type: 'error',
                        title: 'Une erreur est survenue',
                        message: e.response.data.message
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },

        markWritingAsReviewed() {
            console.log(this.storeLoading)
            this.storeLoading = true
            this.loading = true
            console.log(this.storeLoading)

            this.setWritingReviewed()
                .then(() => {
                    this.$router.push('/admin/redactor')
                })
                .catch(e => {
                    this.$store.dispatch('toast', {
                        type: 'error',
                        title: 'Une erreur est survenue',
                        message: e.response.data.message
                    })
                })
                .finally(() => {
                    this.storeLoading = false
                    this.loading = false
                })
        },

        handleError(error, title) {
            this.$store.dispatch('toast', {
                type: 'error',
                title: title,
                message: error.response.data.message
            })
        }
    }
}
</script>

<style scoped>
.gallery {
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    overflow-x: scroll;
    position: relative;
    min-height: 30vh;
}

.gallery li {
    min-width: 30%;
    min-height: 30vh;
    max-height: 30vh;
}

.gallery-card img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.gallery-card {
    position: relative;
}

.gallery-card .radio-block {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    padding: 5px;
    width: 100%;
    background: #fff;
}

.overflow-x-hidden {
    overflow-x: hidden;
}
</style>