import { EventManager } from '@/components/EventManager.js'

const pixabayButton = function (editor) {
    editor.ui.registry.addButton('pixabay', {
        icon: 'image',
        onAction() {
            EventManager.$emit('pixabay:show')
        }
    })
}

export default pixabayButton
